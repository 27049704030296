<template>
    <div>
        <navigation msg="我的企业"></navigation>
        <div class="PLR_2 bg_fff MT_2">
            <div class="flex_row_between PT_2" v-for="(item,index) in 3" @click="goTo()" :key="index">
                <img style="width:1rem;height:1rem" src="../../assets/mycompany.png" alt="">
                <div class="flex_grow_1 ML_4 BB_1_f2f2f2 PTB_3">
                    <div class="textL font_weight_bold">
                        上海振翊软件科技有限公司
                    </div>
                    <div class="PT_2 fontS_12 flex_rowL">
                      注册资本：<span style="margin-right:0.2rem;color: #5580F0;">1000万</span>
                      状态：<span style="color: #5580F0;">存续</span>
                    </div>
                </div>
                <div class="W_10"> ></div>
            </div>
        </div>
    </div>
</template>

<script>
    import '@/assets/css/common.css';
    import navigation from '@/components/navigation/index';

    export default {
        name: "enterpriseList",
        data() {
            return {
                title: ''
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            goTo() {
                this.$router.push('/enterpriseDetail')
            }
        },
        components: {
            navigation
        }
    }
</script>

<style scoped>

</style>